import FlashLib from 'flashlib_onlyplay';
import { winFontStyle } from '../../fontStyles';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import WinAmountAnimated from '../../winAmountAnimated/winAmountAnimated';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import StatesManager from 'Engine/base/states/StatesManager';
import animationCreator from 'Engine/animations/animationCreator';
import animationTypes from '../../animations/animationTypes';
import { gsap } from 'gsap';
import SoundManager from 'Engine/soundManager/SoundManager';
import eBigWinTypes from '../../model/eBigWinTypes';
import eEventTypes from 'Engine/enums/eBaseEventTypes';
import { eSounds } from '../../enums/eSoundTypes';

export default class PopupWin extends FlashLib.MovieClip {
  constructor(data, $displayData) {
    super(data, $displayData);
    this.eventMode = 'static';
    this.orintation = 'H';

    this.symbolSoundsType = {
      4: eSounds.EST_SIMPLE_WIN_0,
      5: eSounds.EST_SIMPLE_WIN_1,
      6: eSounds.EST_SIMPLE_WIN_2,
      7: eSounds.EST_SIMPLE_WIN_3,
      8: eSounds.EST_SIMPLE_WIN_4,
    };
    this.stepsData = {
      [eBigWinTypes.EBWT_BIG_WIN]: {
        animationName: 'big_win_',
        frame: 1,
        sound: eSounds.EST_BIG_WIN,
      },
      [eBigWinTypes.EBWT_MEGA_WIN]: {
        animationName: 'mega_win_',
        frame: 2,
        sound: eSounds.EST_MEGA_WIN,
      },
    };
    this.delayed = true;
    this.stepsSequence = [
      eBigWinTypes.EBWT_BIG_WIN,
      eBigWinTypes.EBWT_MEGA_WIN,
    ];

    this.animationCycleTime = 2000;
    this.animationStack = [];

    this.addListeners();
    this.init();
    // this.initAnimation();
    this._initAnimationLoading();
  }

  init() {
    this.visible = false;
    this.overlay = this.getChildByName('overlay');
    this.wrapper = this.getChildByName('wrapper');
    this.panel = this.wrapper.getChildByName('panel');
    this.panel.visible = false;
    this.overlay.hide();
    this.smallWinContainer = this.wrapper.getChildByName('smallPanel');
    this.smallWinContainer.visible = false;
    this.smallWinAmount = this.smallWinContainer.getChildByName('amount');
    this.smallWinCounter = new WinAmountAnimated(
      this.smallWinAmount,
      winFontStyle
    );
    this.smallWinCounter.setAdaptiveBox(1000);

    this.setTextsStyle();
  }

  setTextsStyle() {
    this.smallWinAmount.style = {
      ...this.smallWinAmount.style,
      ...winFontStyle,
    };
  }

  addListeners() {
    GlobalDispatcher.add('stateEnd:stopWinState', this.complete, this);
    GlobalDispatcher.add('stateStart:showWinState', this.show, this);
    GlobalDispatcher.add('stateStart:stopWinState', this.stop, this);
    if (!window.OPWrapperService.config.skipBlocked)
      this.on('pointertap', () => {
        StatesManager.goToNextState();
      });
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
  }

  onGameResized(data) {
    if (data.isLandscape) {
      this.wrapper.anchorDefault();
      this.wrapper.scale.set(this.wrapper.displayData.scaleX);
      this.orintation = 'H';
    } else if (data.isMobile && data.isPortrait) {
      this.wrapper.centring();
      this.wrapper.scale.set(0.6);
      this.orintation = 'V';
    }
    this.checkPositions();
  }

  checkPositions(){
    if (this.winDataIndex >= 0) {
      if (this.orintation === 'H') {
        this.wrapper.y = this.wrapper.displayData.y;
        this.wrapper.x = this.wrapper.displayData.x;
      } else if (this.orintation === 'V') {
        this.wrapper.y = this.displayData.height / 2 - 630;
        this.wrapper.x = this.displayData.width / 2;
      }
    } else {
      if (this.orintation === 'H') {
        this.wrapper.y = this.wrapper.displayData.y - 76;
        this.wrapper.x = this.wrapper.displayData.x + 152;
      } else if (this.orintation === 'V') {
        this.wrapper.y = this.displayData.height / 2 - 630 - 100;
      }
    }
  }

  async _initAnimation() {

    await animationCreator.createAnimationWithDelayedLoading(animationTypes.BIG_WIN).then((data)=>{
      this.animation = data;
      this.animation.visible = false;
      // this.animation.state.addListener(this.animationListener);
      this.wrapper.getChildByName('animationContainer').addChild(this.animation);
    });
  }

  async _initAnimationLoading(){
    if(this.delayed){
      this.animationPromise = new Promise(async (resolve) => {
        await this._initAnimation();
        this.animation = this.animation;
        this.animationPromise.isFulfilled = true;
        resolve();
      });
    } else {
      this._initAnimation();
    }
  }

  show() {
    this.visible = true;
    this.eventMode = 'static';
    this.alpha = 1;
    let linesLength = EntryPoint.GameModel.winnings.lines.length;
    if (EntryPoint.GameModel.winnings.scatters)
      linesLength += EntryPoint.GameModel.winnings.scatters[0].count ** 1.8;
    const durationAnimation = 4000 * linesLength;
    this.showBigWin(durationAnimation);
  }

  stop() {
    if (this.animationStack.length) {
      this.animationStack = [];
    }
    this.executeAnimation();
    this.smallWinCounter.onStop = () =>
      (this.smallWinCounter.label.text = MoneyFormat.formatCurrency(
        EntryPoint.GameModel.totalWin,
        this.decimals,
        false
      ));
    this.smallWinCounter.stop();
    clearTimeout(this.soundTimeout);
    SoundManager.stop(eSounds.EST_MONEY_GAIN.soundName);
    SoundManager.play(eSounds.EST_TILE.soundName, eSounds.EST_TILE.volume, false, eSounds.EST_TILE.group);
  }

  complete() {
    this.hide();
    this.smallWinCounter.complete();
  }

  hide() {
    this.eventMode = 'none';
    SoundManager.stop(eSounds.EST_MONEY_GAIN.soundName);
    this.playMainBackgroundSound();
    gsap.to(this, {
      alpha: 0,
      duration: 0.4,
      onComplete: () => {
        this.visible = false;
        this.animation.visible = false;
        this.smallWinContainer.visible = false;
        this.animationStack = [];
        this.overlay.hide();
      },
    });
  }
  async checkInitializationWinAnimation() {
    if (!this.animationPromise.isFulfilled) {
      window.OPPreloader.showDelayedPreloader();
      await Promise.all([this.animationPromise]);
      window.OPPreloader.hideDelayedPreloader();
    }
  }

  async showBigWin(duration) {
    this.stopMainBackgroundSound();
    this.decimals = EntryPoint.GameModel.currencyInfo.decimals;
    await this.checkInitializationWinAnimation();
    this.winDataIndex = this.stepsSequence.findIndex((bigWinType) => {
      return (
        EntryPoint.GameModel.winRankCoef[bigWinType] >
        EntryPoint.GameModel.totalWin / EntryPoint.GameModel.bet
      );
    });

    if (this.winDataIndex === -1) {
      this.winDataIndex = this.stepsSequence.length - 1;
    } else {
      this.winDataIndex = this.winDataIndex - 1;
    }
    this.checkPositions();
    if (this.winDataIndex >= 0) {
      this.panel.visible = false;
      this.smallWinContainer.y = this.smallWinContainer.displayData.y;
      this.animation.visible = true;
      this.animation.state.setAnimation(
        0,
        `${
          this.stepsData[this.stepsSequence[this.winDataIndex]].animationName
        }idle`,
        true
      );
      this.overlay.show();
      GlobalDispatcher.dispatch(
        eEventTypes.EBET_BIG_WIN_ANIMATION_VISIBLE_CHANGE,
        true
      );

      duration += 2000 + 4000 * this.winDataIndex;
    } else {
      this.panel.visible = true;
    }

    this.createAnimationStack(this.winDataIndex);

    this.startAnimatedValue = 0;
    this.nextAnimatedValue = this.animationStack.length
      ? (EntryPoint.GameModel.totalWin / (this.animationStack.length + 2)) * 2
      : EntryPoint.GameModel.totalWin;
    this.animationCycleTime = (duration / (this.animationStack.length + 2)) * 2;

    this.smallWinContainer.visible = true;
    this.smallWinCounter.animate(
      this.startAnimatedValue,
      this.nextAnimatedValue,
      this.decimals,
      this.animationCycleTime,
      200000
    );
    this.smallWinCounter.onStop = this.showBigWinAnimation.bind(this);

    let soundAlias = eSounds.EST_MONEY_GAIN;
    if (this.animationStack[this.animationStack.length - 1]) {
      soundAlias = this.animationStack[this.animationStack.length - 1].sound;
      SoundManager.play(soundAlias.soundName, soundAlias.volume, false, soundAlias.group);
      const offset = 800;
      this.soundTimeout = setTimeout(
        () =>
          SoundManager.play(eSounds.EST_MONEY_GAIN.soundName, eSounds.EST_MONEY_GAIN.volume, true, eSounds.EST_MONEY_GAIN.group),
        SoundManager.getSound(soundAlias.soundName).duration * 1000 - offset
      );
    } else {
      let symbolType;
      EntryPoint.GameModel.winnings.lines[0].path.forEach(
        (place, reelIndex) => {
          if (EntryPoint.GameModel.reels[reelIndex][place - 1] !== 1)
            symbolType = EntryPoint.GameModel.reels[reelIndex][place - 1];
        }
      );

      if (symbolType !== undefined){
        const { soundName, volume, group } = this.symbolSoundsType[symbolType];
        SoundManager.play(soundName, volume, false, group);
      }
      SoundManager.play(soundAlias.soundName,soundAlias.volume, true, soundAlias.group);
    }
  }

  showBigWinAnimation() {
    this.smallWinCounter.onStop = this.executeAnimation.bind(this);
    this.startAnimatedValue = this.nextAnimatedValue;
    this.nextAnimatedValue =
      (EntryPoint.GameModel.totalWin / this.animationStack.length) * 2;
    this.executeAnimation();
  }

  createAnimationStack(index) {
    this.stepsSequence.forEach((bigWinType, i) => {
      if (i <= index) {
        const el = this.stepsData[bigWinType];
        const start = {
          name: el.animationName + 'start',
          type: 'start',
          sound: el.sound,
          coef: EntryPoint.GameModel.winRankCoef[bigWinType],
        };
        const idle = {
          name: el.animationName + 'idle',
          type: 'idle',
          sound: el.sound,
          coef: EntryPoint.GameModel.winRankCoef[bigWinType],
        };

        this.animationStack = [...this.animationStack, start, idle];
      }
    });
  }

  executeAnimation() {
    this.timeout && clearTimeout(this.timeout);
    if (!this.animationStack.length) {
      GlobalDispatcher.dispatch('bigWinContainer:stopState');
      return setTimeout(
        () => GlobalDispatcher.dispatch('bigWinContainer:nextState'),
        1500
      );
    }

    const animation = this.animationStack.shift();

    if (animation.type === 'start') {
      this.animateWinning();
    }

    if (animation.type === 'idle') {
      this.startAnimatedValue = this.nextAnimatedValue;
      this.nextAnimatedValue =
        (EntryPoint.GameModel.totalWin / this.animationStack.length) * 2;
      this.setIdleTimeout();
    }
  }

  animateWinning() {
    this.smallWinCounter.animate(
      this.startAnimatedValue,
      this.nextAnimatedValue,
      this.decimals,
      this.animationCycleTime,
      200000
    );
  }

  setIdleTimeout() {
    this.executeAnimation();
  }

  stopMainBackgroundSound() {
    const backgroundMainGameSound = SoundManager.getSound(
      eSounds.EST_BACKGROUND.soundName
    );
    const timeLine = gsap.timeline();
    timeLine
      .to(backgroundMainGameSound, { volume: 0, duration: 0.5 })
      .call(() => {
        SoundManager.pause(eSounds.EST_BACKGROUND.soundName);
      });
  }

  playMainBackgroundSound() {
    const backgroundMainGameSound = SoundManager.getSound(
      eSounds.EST_BACKGROUND.soundName
    );
    const timeLine = gsap.timeline();
    timeLine
      .call(() => {
        SoundManager.play(eSounds.EST_BACKGROUND.soundName, eSounds.EST_BACKGROUND.volume, true, eSounds.EST_BACKGROUND.group);
      })
      .fromTo(
        backgroundMainGameSound,
        { volume: 0 },
        { volume: 0.5, duration: 1.5 }
      );
  }
}
