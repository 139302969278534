import GlobalDispatcher from '../../events/GlobalDispatcher';
import BaseGameModel from "../../base/model/BaseGameModel";
import EntryPoint from "../../EntryPoint";
import eBaseEventTypes from 'Engine/enums/eBaseEventTypes';

export default class SlotGameModel extends BaseGameModel {
    constructor() {
        super();
        this._lastResponseData = null;
        this._currency = '';
        this._balance = 0;
        this._lines = 3;
        this._bet = 1;
        this._reels = [[]];
        this._winnings = {};
        this._betPossible = [1, 2, 3, 4, 5];
        this._linesPossible = [3];
        this._betIndex= 0;
        this._freeSpins = {
            left: 0,
            total: 0,
            totalWin: 0
        };

        this._isAutoPlay = false;
        this._isSpinWithoutBalanceCheck = false;
    }

    init() {
        this.addListeners();
        EntryPoint.GameModel = this;
        GlobalDispatcher.dispatch('model:init');
    }

    addListeners() {
        GlobalDispatcher.add('ui:changeBet', this.onChangeBetPressed, this);
        GlobalDispatcher.add('ui:changeLines', this.onChangeLinesPressed, this);
        GlobalDispatcher.add('ui:changeAutoPlay', this.onChangeAutoPlayPressed, this);
        GlobalDispatcher.add('ui:setMaxBet', this.onMaxBetPressed, this);
        GlobalDispatcher.add(eBaseEventTypes.EBET_IDLE_STATE__START, this.onIdleStateStart, this);
    }

    onMaxBetPressed(data) {
        this.setMaxBet(data.params);
    }

    onChangeBetPressed(data) {
        this.changeBet(data.params);
    }

    onChangeLinesPressed(data) {
        this.changeLines(data.params);
    }

    onChangeAutoPlayPressed(data) {
        this.changeAutoPlay(data.params);
    }

    onIdleStateStart() {
        if (this._isLastFreeBet) {
            this.betPossible = this._betPossible;
            if (typeof this._lastResponseData.spin.betIndex === 'number') {
                this.betIndex = this._lastResponseData.spin.betIndex;
            } else if (this._lastResponseData.spin.bet) this.bet = this._lastResponseData.spin.bet;
        }
    }

    setNewData(data) {
        this._lastWin = this.totalWin || this._lastWin;
        this._lastResponseData = data;
        this._currencyInfo = data.currencyInfo || this._currencyInfo;
        this._paytableInfo = data.paytableInfo || this._paytableInfo;


        if (data.balance || data.balance === 0) {
            this._balance = data.balance;
        }

        if (data.params) {
            this._betPossible = data.params.betPossible || this._betPossible;
            this._linesPossible = data.params.linesPossible || this._linesPossible;
        }
        if (data.current) {
            let maxIndex = this._betPossible.filter(b => b <= this._balance).length - 1;
            if (maxIndex <= 0) maxIndex = 0;
            // pid: 217 PMBelarus
            const defaultBetIndex = OPUtility.urlParser.getParam('launchedForPid') === '217' ? 0 : Math.min(this._betPossible.length - 2, maxIndex);
            this._betIndex = typeof (data.current.betIndex) === 'number' ? data.current.betIndex : (data.current.bet ? this._betPossible.indexOf(data.current.bet) : defaultBetIndex);
            this._bet = this._betPossible[this._betIndex];
            this._lines = data.current.lines || this._lines;
        }

        this._freebetsIsActive = data.freebets && (data.freebets.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_ACTIVE || data.freebets.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_PLAYED);
        if (data.freebets) {
            this._freeBetPossible = data.freebets.bet_levels;
        }
        if (this._freebetsIsActive) {
            this.betPossible = this._freeBetPossible;
            this.betIndex = data.freebets.bet_level;
        } else {
            this.betPossible = this._betPossible;
        }

        window.OPWrapperService.freeBetsController.setData(data.freebets);

        this._isLastFreeBet = false;
        if (data.spin) {
            this._reels = data.spin.reels || this._reels;
            this._winnings = data.spin.winnings || {};
            window.OPWrapperService.ControllerStatistic.win = this.totalWin;
            if (typeof data.spin.betIndex === 'number' && !this._freebetsIsActive) {
                this.betIndex = data.spin.betIndex;
            } else if (data.spin.bet && !this._freebetsIsActive) this.bet = data.spin.bet;
            this._isLastFreeBet = data.freebets && data.freebets.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_PLAYED;
                if (this._freebetsIsActive || this._isLastFreeBet) {
                    window.OPWrapperService.ControllerStatistic.increaseFreeBetsCount();
                } else {
                    window.OPWrapperService.ControllerStatistic.increaseSpinCount(this.betIndex, EntryPoint.AutoPlaySettings.active);
                }
        }
        if (data.freeSpins) {
            this._freeSpins = data.freeSpins || null;
        }
    }

    setMaxBet(data) {
        let result = this.calculateMaxBet(data);
        this.bet = result.bet;
        this.lines = result.lines;

        /*for (let i = this._betPossible.length - 1; i >= 0; i--) {
            let betPossible = this._betPossible[i];
            for (let j = this._linesPossible.length - 1; j >= 0; j--) {
                let linePossible = this._linesPossible[j];
                if (linePossible + betPossible <= this.balance) {
                    this.bet = betPossible;
                    this.lines = linePossible;
                    return true
                }
            }
        }
        return false;*/
    }

    calculateMaxBet() {
        let result = {
            lines: this.lines,
            bet: this.bet
        };

        if (this._betPossible.length) result.bet = this._betPossible[this._betPossible.length - 1];
        // this._betPossible.forEach(bet => {
        //     if (this.balance >= bet) {
        //         result.bet = bet;
        //     }
        // });

        return result;
    }

    changeBet(index) {
        if (index >= this._betPossible.length) index = this._betPossible.length - 1;
        if (index < 0) index = 0;

        this.betIndex = index;
    }

    changeLines(data) {
        this.lines = this.calculateNewLines(data);
    }

    calculateNewLines(data) {
        let index = this._linesPossible.indexOf(this.lines);
        let indexDimension = 0;
        switch (data) {
            case 'increase':
                indexDimension = 1;
                break;
            case 'decrease':
                indexDimension = -1;
                break;
            default:
                index = data;
        }
        let newIndex = index + indexDimension;
        if (newIndex === this._linesPossible.length) {
            newIndex = 0;
        }
        if (newIndex < 0) {
            newIndex = this._linesPossible.length - 1;
        }
        return this._linesPossible[newIndex];
    }

    changeAutoPlay(data) {
        if (data === undefined) {
            this.isAutoPlay = !this.isAutoPlay;
        } else {
            this.isAutoPlay = data;
        }
    }

    get balance() {
        return this._balance;
    }

    set balance(value) {
        this._balance = value;
        GlobalDispatcher.dispatch('model:balanceChanged', this.balance);
    }

    get bet() {
        return this._bet;
    }

    set bet(value) {
        const betIndex = this.betPossible.indexOf(value);
        if (this._bet === value && this._betIndex === betIndex) return;
        this._bet = value;
        this._betIndex = betIndex
        GlobalDispatcher.dispatch('model:betChanged', this.bet);
    }

    get betIndex() {
        return this._betIndex;
    }

    set betIndex(value) {
        const bet = this.betPossible[value];
        if (this._bet === bet && this._betIndex === value) return;
        this._betIndex = value;
        this._bet = bet;
        GlobalDispatcher.dispatch('model:betChanged', this.bet);
    }

    get lines() {
        return this._lines;
    }

    set lines(value) {
        this._lines = value;
        GlobalDispatcher.dispatch('model:linesChanged', this.lines);
    }

    get reels() {
        return this._reels;
    }

    set reels(value) {
        this._reels = value;
    }

    get winnings() {
        return this._winnings;
    }

    set winnings(value) {
        this._winnings = value;
    }

    get totalBet() {
        return this.bet * this.lines;
    }

    get totalWin() {
        if (this._winnings) {
            return this.winnings.totalWin;
        }
        return 0;
    }

    get lastWin() {
        return this._lastWin;
    }

    set betPossible(value) {
        if (this._currentBetPossible === value) return;
        this._currentBetPossible = value;
        GlobalDispatcher.dispatch('model:betPossibleChanged', this._currentBetPossible);
    }

    get betPossible() {
        return this._currentBetPossible;
    }

    get linesPossible() {
        return this._linesPossible;
    }

    get freeSpins() {
        return this._freeSpins;
    }

    get paytableInfo() {
        return this._paytableInfo;
    }

    get currencyInfo() {
        return this._currencyInfo;
    }

    get isFreeSpins() {
        return this.freeSpins && this.freeSpins.total > 0 && this.freeSpins.left !== 0
    }

    get isFreeSpinsMode() {
        return this._isFreeSpinsMode;
    }

    get isAutoPlay() {
        return this._isAutoPlay;
    }

    set isAutoPlay(value) {
        this._isAutoPlay = value;
        GlobalDispatcher.dispatch('model:autoPlayChanged', this.isAutoPlay);
    }
    get isSpinWithoutBalanceCheck() {
        return (this._freebetsIsActive && !OPWrapperService.freeBetsController.isLastFreeBet) || this._isSpinWithoutBalanceCheck;
    }
}
