import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import SoundManager from 'Engine/soundManager/SoundManager';
import eSymbolIndex from '../symbols/eSymbolIndex';
import { gsap } from 'gsap';
import FlashLib from 'flashlib_onlyplay';
import { eSounds } from '../enums/eSoundTypes';

export default class ControllerBonusJokerAnimations extends PIXI.Container {
  constructor(ControllerReels) {
    super();
    this.ControllerReels = ControllerReels;

    this.addListeners();
  }
  init() {
    this._symbolPositions = this._getSymbolPositions();
    this._symbolPoolLang = 6;
    this._symbolPool = this._createSymbolPool();
    this._lastJokerPositions = null;
  }
  addListeners() {
    GlobalDispatcher.add('stateStart:animationJackpotterState', this.startWinAnimationJoker, this);
  }

  startWinAnimationJoker() {
    const delay = 1000;
    const animations = EntryPoint.GameModel.bonusGameAnimatonsPaths;
    animations.forEach((step, stepIndex) => {
      step.forEach((path, pathIndex) => {
        const startPosition = this._symbolPositions[path[0][0]][path[0][1]];
        const endPosition = this._symbolPositions[path[1][0]][path[1][1]];
        const coinValue = EntryPoint.GameModel.coinSymbolsValuesList[stepIndex + 1][path[1][0]][path[1][1]] * EntryPoint.GameModel.bet;

        // joker symbol animation
        if (this._lastJokerPositions !== startPosition) {
          const jokerSymbol = this.ControllerReels.reels[path[0][0]].symbols[path[0][1] + 1];

          setTimeout(() => {
            jokerSymbol.startAnimation()
            const tigerJokerSound = SoundManager.getSound(eSounds.EST_JOKER_WITH_TIGER.soundName);
            if (!tigerJokerSound?.isPlaying) SoundManager.play(eSounds.EST_JOKER_WITH_TIGER.soundName, eSounds.EST_JOKER_WITH_TIGER.volume, false, eSounds.EST_JOKER_WITH_TIGER.group);
          },delay * stepIndex);

          this._lastJokerPositions = startPosition;
        }

        // coin fly animation
        const onCompleteAnimateSymbolFly = () => {
          const realsymbol = this.ControllerReels.reels[path[1][0]].symbols[path[1][1] + 1];

          if (realsymbol.id !== eSymbolIndex.ESI_JOKER && realsymbol.id !== eSymbolIndex.ESI_BONUS_JOKER) {
            if (realsymbol.id !== eSymbolIndex.ESI_COIN) {
              realsymbol.changeSymbol(eSymbolIndex.ESI_COIN);
              GlobalDispatcher.dispatch('specilalSymbol: hitTheBar', realsymbol); //transfer symbol
            }
            realsymbol.addValue(coinValue);
          }

          const lastAnimationsIndex = animations.length - 1;
          const lastPathIndex = animations[lastAnimationsIndex].length - 1;

          if (animations[lastAnimationsIndex][lastPathIndex] === path) {
            this._lastJokerPositions = null;
            GlobalDispatcher.dispatch('ControllerSlot: jackpotterAnimationComplete');
          }
        };

        this._animateSymbolFly(
          startPosition,
          endPosition,
          coinValue,
          delay * stepIndex,
          pathIndex,
          onCompleteAnimateSymbolFly
        );
      });
    });
  }

  _animateSymbolFly(startPosition, endPosition, coinValue, delay, pathIndex, onComplete) {
    setTimeout(() => {
      const currentSymbol = this._symbolPool.pop();
      currentSymbol.changeSymbol(eSymbolIndex.ESI_COIN);
      currentSymbol.setValue(coinValue);
      this.addChild(currentSymbol);
      this.animation = gsap.timeline();
      this.animation
        .set(currentSymbol, { x: startPosition.x, y: startPosition.y })
        .fromTo(currentSymbol, { alpha: 0 }, { alpha: 1, duration: 0.3 }, 'show')
        .to(currentSymbol.scale, { x: 1.2, y: 1.2, duration: 0.3, ease: "bounce.out" }, 'show')
        .call(() => {
          if (pathIndex === 0) SoundManager.play(eSounds.EST_FLY_COIN_SYMBOL.soundName, eSounds.EST_FLY_COIN_SYMBOL.volume, false, eSounds.EST_FLY_COIN_SYMBOL.group)
        })
        .to(currentSymbol.scale, { x: 1, y: 1, duration: 0.4 }, 'move')
        .to(currentSymbol, { x: endPosition.x, y: endPosition.y, duration: 0.4 }, 'move')
        .call(onComplete.bind(this))
        .to(currentSymbol, { alpha: 0, duration: 0.15 }, 'hide')
        .call(() => {
          this.removeChild(currentSymbol);
          this._symbolPool.push(currentSymbol);
        });
    }, delay);
  }

  _createSymbolPool() {
    const pool = [];
    for (let i = 0; i < this._symbolPoolLang; i++) {
      const symbol = FlashLib.createItemFromLibrary('movieClips/symbols/symbol', 'GameFlashLib');
      pool.push(symbol);
    }
    return pool;
  }

  _getSymbolPositions() {
    const symbolPositions = [];
    this.ControllerReels.reels.forEach((reel, reelIndex) => {
      symbolPositions.push([]);
      for (let i = 1; i < reel.symbols.length - 1; i++) {
        const symbol = reel.symbols[i];
        symbolPositions[reelIndex][i - 1] = this.toLocal({ x: 0 , y: 0 }, symbol)
      }
    });
    return symbolPositions;
  }
}
